"use client";

import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { useAuth, ValidationErrors } from '@shesha-io/reactjs';
import { URL_FORGOT_PASSWORD } from '@/routes';
import { LoginPageWrapper } from './wrapper';

interface ILoginForm {
  readonly userNameOrEmailAddress: string;
  readonly password: string;
  readonly rememberMe?: boolean;
}

const Login = () => {
  const {
    loginUser,
    errorInfo,
    isInProgress: { loginUser: isLoggingInUser },
  } = useAuth();

  const [form] = Form.useForm<ILoginForm>();
  const [teamsContext, setTeamsContext] = useState<microsoftTeams.app.Context | null>(null);
  const [loading, setLoading] = useState(false);
  const [showRootLoginForm, setShowRootLoginForm] = useState(false);

  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.app.getContext().then((context) => {
        setTeamsContext(context);
        console.log("Teams context", context);
      });
    }).catch((error) => {
      console.error("Microsoft Teams initialization error", error);
    });
  }, []);

  const handleMicrosoftSignIn = async () => {
    if (teamsContext) {
      try {
        const username = teamsContext.user.userPrincipalName;
        const uniqueId = teamsContext.user.id;

        if (!username || !uniqueId) {
          throw new Error('Missing required user information from Teams context');
        }

        const password = uniqueId.split('-')[0];

        const payload: ILoginForm = {
          userNameOrEmailAddress: username,
          password: password,
          rememberMe: false,
        };

        loginUser(payload);
        console.log("User signed in with Teams context:", payload);

      } catch (error) {
        console.error("Teams sign-in error", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Teams context not available");
    }
  };

  const handleRootLogin = (payload: ILoginForm) => {
    loginUser(payload);
  };

  return (
    <LoginPageWrapper
      className="login-page"
      heading={showRootLoginForm ? "Log in as Root User!" : "Admin Portal"}
      hint={showRootLoginForm ? "" : "Let's Transform Your Workplace With Botsa!"}
    >
      {!showRootLoginForm ? (
        <div className="sign-in-container" style={{ textAlign: "center" }}>
          {loading ? (
            <Spin size="large" />
          ) : (
            <>
              <Button
                onClick={handleMicrosoftSignIn}
                block
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#2F2F2F',
                  color: '#FFFFFF',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '12px 24px',
                  fontSize: '15px',
                  fontWeight: 600,
                  cursor: 'pointer',
                  width: 'auto',
                  height: '41px',
                  fontFamily: 'Segoe UI, sans-serif',
                  margin: '0 auto'
                }}
              >
                <span style={{ display: 'inline-block', marginRight: '12px', marginTop: '10px' }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <rect fill="#F25022" x="1" y="1" width="10" height="10"/>
                    <rect fill="#7FBA00" x="13" y="1" width="10" height="10"/>
                    <rect fill="#00A4EF" x="1" y="13" width="10" height="10"/>
                    <rect fill="#FFB900" x="13" y="13" width="10" height="10"/>
                  </svg>
                </span>
                <span>Sign in with Microsoft</span>
              </Button>

              <Button
                type="link"
                htmlType="button"
                className="login-form-button"
                block
                onClick={() => setShowRootLoginForm(true)}
              >
                Log in with Root User
              </Button>
            </>
          )}
        </div>
      ) : (
        <Form form={form} onFinish={handleRootLogin}>
          <ValidationErrors error={errorInfo} />

          <FormItem
            name="userNameOrEmailAddress"
            help="This field is required"
            rules={[{ required: true, message: "Please enter your username" }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Username" />
          </FormItem>

          <FormItem
            name="password"
            help="This field is required"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password
              autoComplete="on"
              prefix={<LockOutlined />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              placeholder="Password"
            />
          </FormItem>

          <FormItem className="un-authed-btn-container">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
              loading={isLoggingInUser}
              size="large"
            >
              {isLoggingInUser ? 'Signing in....' : 'Sign In'}
            </Button>

            <Button
                type="link"
                htmlType="button"
                className="login-form-button"
                block
                onClick={() => setShowRootLoginForm(false)}
              >
                Sign in with Microsoft Teams
              </Button>
          </FormItem>

          <div className="custom-form-item">
            <Checkbox>Remember me</Checkbox>

            <Link href={URL_FORGOT_PASSWORD} className="login-form-forgot">
              Forgot password
            </Link>
          </div>
        </Form>
      )}
    </LoginPageWrapper>
  );
};

export default Login;
